html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  min-height: 100vh;
  overflow: auto;
  text-align: center;
}

.form-signin {
  width: 100%;
  max-width: 360px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.chart-container {
  min-height: 630px;
}

.dashboard-card {
  min-height: 280px;
}

.dashboard-card .content {
  height: 230px;
  overflow: auto;
}

.line-through {
  text-decoration: line-through;
}

.custom-tabs {
  position: absolute;
  top: -28px;
  left: 12px;
  right: 12px;
  background-color: #2bbbad;
  padding: 4px 12px;
}

.custom-tabs .selected {
  background-color: #009281 !important;
}

.custom-dropdown {
  min-width: 100px;
  display: inline-block;
  z-index: 1000 !important;
}

.custom-dropdown .custom-list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.custom-dropdown .custom-list a {
  display: block;
  padding: 0 4px;
  z-index: 1000;
}

.custom-dropdown .custom-list a:hover {
  background-color: rgb(204, 204, 204);
}

.cursor-pointer {
  cursor: pointer;
}

.task-card {
  height: 260px;
  z-index: 1 !important;
}

.task-card .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 120px;
  overflow: hidden;
}

.task-card .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.get-started-btn{
  background-color: #353535 !important;
  outline: 1px solid #353535 !important;
  border: 1px solid #353535 !important;
  font-size: 12px !important;

}

.nav-link{
  margin-right: 32px;
  font-size: 12px !important;
  color: #E05C26 !important;
}

.navbar{
  background-color: #FFEDE5 !important;
}

.banner img{
  width: 100%
}

.banner-divider{
  width: 237px;
  height: 5px;
  background-color: #545454;
}

.text-orange{
  color: #E05C26 !important;
}

.what-we-do{
  background-color: #E47447;
}

.what-we-do .item{
  text-align: center;
}

.what-we-do .item img{
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.what-we-do h2{
  text-align: center;
}

.what-we-do .dots{
  width: 80px;
  position: absolute;
  display: none;
}

.what-we-do .item .title{
  display: block;
  color: white
}
.what-we-do .item .description{
  display: block;
  color: white
}

.banner h1{
  font-size: 48px;
  font-weight: 500;
}

.about .title{  
  text-align: center; 
}

.about-divider{
  width: 237px;
  height: 5px;
  background-color: #E47447;
}

.contact{
  background-color: #E47447;
}

.contact .btn{
  background-color: #353535;
  background-color: #353535 !important;
  outline: 1px solid #353535 !important;
  border: 1px solid #353535 !important;
}

.contact h2{
  text-align: center;
}

.contact .info{
  display: flex;
  flex-wrap: wrap;
}

.contact .item{
  min-width: 180px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.contact .item img{
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.contact .dots{
  width: 80px;
  position: absolute;
  display: none;
  bottom: 8px;
  right:  0;
}

.our-team h2{
  text-align: center; 
}


.footer{
  background-color: #E47447;
}

.footer .item{
  text-align: center;
}

.footer .item img{
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.footer h2{
  text-align: center;
}

.footer .dots{
  width: 80px;
  position: absolute;
  display: none;
}

.footer .white-logo{
  height: 50px
}


.footer .info{
  display: flex;
  flex-wrap: wrap;
}

.footer .item{
  min-width: 180px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.footer .item img{
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.footer .item h4{
  font-size: 15px;
}

.footer .item p{
  font-size: 12px;
}


@media only screen and (min-width: 992px) {
  .navbar-links{
    display: flex;
    flex-direction: row-reverse;
  }

  .what-we-do .dots,
  .contact .dots,
  .footer .dots{
    display: block !important;
  }
}